body {
  padding-top: 10%;
  padding-bottom: 40px;
  background-color: #fff;
}

.form-login {
  max-width: 380px;
  padding: 15px;
  margin: 0 auto;
}
.form-login .form-login-heading {
  font-size: 1.25em;
  letter-spacing: 0.05em;
  margin-bottom: 40px;
  text-align: center;
}
.form-login .form-login-heading span {
  color: #ccc;
  font-size: 1em;
  font-weight: normal;
  padding-left: 10px;
  padding-right: 10px;
}

.form-login label {
  font-weight: normal;
}

.form-login .form-control {
  position: relative;
  height: auto;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
}
.form-login input[type="email"] {
  margin-bottom: 10px;
}
.form-login input[type="password"] {
  margin-bottom: 20px;
}